import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license-pro";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { Provider } from "jotai";
import { QueryClientProvider } from "@tanstack/react-query";

import { MUI_LICENSE_KEY, TITLE } from "@/constants";
import { router } from "@/routes";
import { userTheme } from "@/theme";
import { AuthorizationProvider, PushNotificationsProvider, NotificationsProvider } from "@/context";
import { appDataStore } from "@/stores";
import { client } from "@/services/queryClient";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./App.css";

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

export function App() {
  document.title = TITLE.AppTitle;

  return (
    <QueryClientProvider client={client}>
      <Provider store={appDataStore}>
        <ThemeProvider theme={userTheme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AuthorizationProvider>
              <NotificationsProvider>
                <PushNotificationsProvider>
                  <RouterProvider router={router} />
                </PushNotificationsProvider>
              </NotificationsProvider>
            </AuthorizationProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  );
}

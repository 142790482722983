import { Stack, Typography } from "@mui/material";
import { AR_SHOW_SUBJECT_MATTER_EXPERTS_LOCATORS } from "@/constants";
import { ARDiscipline } from "@/interfaces";

interface ARShowSubjectMatterExpertsProps {
  approvalRequestDisciplines: ARDiscipline[];
}

export function ARShowSubjectMatterExpertsComponent({ approvalRequestDisciplines }: ARShowSubjectMatterExpertsProps) {
  const renderDisciplineSmeNames = () => {
    return approvalRequestDisciplines.map((item, index) => {
      const hasSecondaryApprover = item.secondaryApproverName !== undefined;

      return (
        <Stack flexDirection="row" columnGap="0.75rem" flexWrap="nowrap">
          {/* Disciplines */}
          <Typography
            maxWidth="25%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            textAlign="right"
            data-testid={AR_SHOW_SUBJECT_MATTER_EXPERTS_LOCATORS.DISCIPLINE_NAME + "-" + index}
            color="grey"
            width="100%"
            title={item.name}
          >
            {item.name}
          </Typography>

          {/* Approvers */}
          <Stack spacing={1} maxWidth="75%">
            <Typography
              data-testid={AR_SHOW_SUBJECT_MATTER_EXPERTS_LOCATORS.FIRST_APPROVER_NAME + "-" + index}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              title={item.primaryApproverName}
            >
              {item.primaryApproverName}
            </Typography>
            {hasSecondaryApprover ? (
              <Typography
                data-testid={AR_SHOW_SUBJECT_MATTER_EXPERTS_LOCATORS.SECOND_APPROVER_NAME + "-" + index}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                title={item.secondaryApproverName}
              >
                {item.secondaryApproverName}
              </Typography>
            ) : null}
          </Stack>
        </Stack>
      );
    });
  };

  return (
    <Stack data-testid={AR_SHOW_SUBJECT_MATTER_EXPERTS_LOCATORS.PARENT_COMPONENT} spacing={2}>
      <Typography mb="0.75rem">Discipline SMEs</Typography>
      {renderDisciplineSmeNames()}
    </Stack>
  );
}

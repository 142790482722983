import { createRef, useEffect, useMemo } from "react";
import { Box, Button, List, ListItem, Stack } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { APPROVAL_REQUEST_PATH, FILE_NAMES, PERMIT_MESSAGE, PERMIT_STATEMENTS } from "@/constants";
import { formatDate } from "@/utils";
import { useApprovalRequestPermit } from "@/hooks";
import { PermitDiscipline, PermitDraftBanner } from "./";
import { ApprovalRequestStatus } from "@/interfaces";
import { generatePath } from "react-router-dom";
import { useSetAtom } from "jotai";
import { snackBarAtom } from "@/stores";
import { SnackBarComponent } from "@/components/SnackBarComponent";
import { PDF_WIDTH_IN_PX } from "@/constants/numbers";
import PermitMapPlaceholder from "./PermitMapPlaceholder";
import { RichTextEditor, RichTextEditorRef } from "@/components/rich-text-editor";
import { Content1, Header, SubTitle, Title1, Title2 } from "./PermitTypography";
import { GridContainer, GridItem } from "./PermitGrid";

interface PermitViewProps {
  approvalRequestId: string;
  approvalRequestStatus: ApprovalRequestStatus;
  isPrintPreview: boolean;
  version?: string;
  onPrintClick: () => void;
}

export function PermitView({
  approvalRequestId,
  approvalRequestStatus,
  isPrintPreview,
  version,
  onPrintClick
}: PermitViewProps) {
  const setSnackBar = useSetAtom(snackBarAtom);
  const { data: permitData } = useApprovalRequestPermit(approvalRequestId, version);

  const editorRef = createRef<RichTextEditorRef>();

  useEffect(() => {
    editorRef.current?.reset(permitData?.description ?? "");
  }, [permitData?.description, editorRef]);

  const isPermitIssuedOrHasActiveVersion = useMemo(() => {
    const hasActiveVersion = version?.split(".")[1].toString() === "0";
    return approvalRequestStatus === ApprovalRequestStatus.PermitIssued || hasActiveVersion;
  }, [approvalRequestStatus, version]);

  async function onClickCopyLink() {
    const permitViewPath = generatePath(APPROVAL_REQUEST_PATH.PERMIT_PREVIEW, { approvalRequestId: approvalRequestId });
    const versionParam = `?version=${version}`;
    await navigator.clipboard.writeText(`${window.location.origin}${permitViewPath}` + (version ? versionParam : ""));
    setSnackBar({
      message: PERMIT_MESSAGE.LINK_COPY_SUCCESSFUL,
      open: true
    });
  }

  const queryStatementInElements = useMemo(
    () =>
      permitData?.queriesStatement.split("<br/>").map((line, _) => (
        <GridItem>
          <Content1>{line}</Content1>
        </GridItem>
      )),
    [permitData?.queriesStatement]
  );

  return (
    <>
      <Stack
        data-testid="permit-content"
        rowGap={isPermitIssuedOrHasActiveVersion ? "1rem" : "6rem"}
        sx={{ position: "absolute", width: isPrintPreview ? PDF_WIDTH_IN_PX : "max-width" }}
      >
        {isPermitIssuedOrHasActiveVersion ? null : <PermitDraftBanner approvalRequestStatus={approvalRequestStatus} />}
        <GridContainer>
          <GridContainer alignItems="center">
            <GridItem xs={4.5}>
              <Box
                component="img"
                alt="Rio Tinto Permit"
                src={`${import.meta.env.REACT_APP_AUTH_REDIRECT_URI}/images/${FILE_NAMES.COMPANY_LOGO}`}
                data-testid="permitLogo"
                height={80}
              ></Box>
            </GridItem>
            <GridItem xs={4.5}>
              <Header sx={{ textAlign: "center", ml: "1rem" }}>{PERMIT_STATEMENTS.TITLE}</Header>
            </GridItem>
            <GridItem xs={3}>
              <Header sx={{ textAlign: "left", ml: "1rem" }}>{permitData?.referenceNo}</Header>
            </GridItem>
          </GridContainer>
          <GridContainer ml="1rem">
            <GridItem xs={9}>
              <Title1>{permitData?.title}</Title1>
            </GridItem>
            <GridItem xs={3}>
              <Title1>Version {permitData?.approvalRequestVersion}</Title1>
            </GridItem>
          </GridContainer>
          <GridContainer ml="1rem">
            <GridItem xs={4.5}>
              <GridContainer>
                <GridItem>
                  <SubTitle>{PERMIT_STATEMENTS.SITE_PROJECT_TITLE}</SubTitle>
                </GridItem>
                <GridItem>
                  <Title1>
                    {permitData?.site}, {permitData?.project}
                  </Title1>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={4.5}>
              <GridContainer>
                <GridItem>
                  <Title2>{PERMIT_STATEMENTS.CLEARING_TITLE}</Title2>
                </GridItem>
                <GridItem>
                  <Header>{permitData?.clearing ?? 0}</Header>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={3}>
              <GridContainer>
                <GridItem>
                  <Title2>{PERMIT_STATEMENTS.EXPIRY_TITLE}</Title2>
                </GridItem>
                <GridItem>
                  <Header>{formatDate(permitData?.expiryDate)}</Header>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer ml="1rem" mt="1rem" alignContent="flex-start">
            <GridItem xs={9}>
              <GridContainer mr="1rem">
                {isPermitIssuedOrHasActiveVersion ? (
                  <>
                    <Box
                      component="img"
                      src={`${import.meta.env.REACT_APP_AUTH_REDIRECT_URI}/images/${FILE_NAMES.PERMIT_Map}`}
                      alt="Rio Tinto Permit Map"
                      data-testid="permitMap"
                      sx={{ aspectRatio: "1 / 1" }}
                      width="100%"
                    />
                    <i>* For full due diligence undertaken for this permit, please refer in LAMS for full detail.</i>
                  </>
                ) : (
                  <PermitMapPlaceholder />
                )}
              </GridContainer>
            </GridItem>
            <GridContainer xs={3} mt="1rem" alignContent="flex-start" rowGap="1.5rem">
              <GridContainer>
                <GridItem>
                  <SubTitle>{PERMIT_STATEMENTS.AUTHORISED_BY_TITLE}</SubTitle>
                </GridItem>
                <GridItem>
                  <Title1>{permitData?.authorisedBy}</Title1>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem>
                  <SubTitle>{PERMIT_STATEMENTS.COORDINATOR_TITLE}</SubTitle>
                </GridItem>
                <GridItem>
                  <Title1>{permitData?.coordinator}</Title1>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem>
                  <SubTitle>{PERMIT_STATEMENTS.OWNER_TITLE}</SubTitle>
                </GridItem>
                <GridItem>
                  <Title1>{permitData?.permitOwner}</Title1>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem>
                  <List sx={{ listStyleType: "disc", ml: "2rem", p: 0 }}>
                    {permitData?.permitOwnerCompliance.map((string, index) => (
                      <ListItem
                        data-testid={`permitOwnerCompliance-${index}`}
                        key={index}
                        sx={{ display: "list-item", pl: 0 }}
                      >
                        {string}
                      </ListItem>
                    ))}
                  </List>
                </GridItem>
              </GridContainer>
            </GridContainer>
          </GridContainer>
          <GridContainer ml="1rem" mt="1rem" rowGap="1.5rem">
            <GridContainer rowGap="1.5rem">{queryStatementInElements}</GridContainer>
            <GridContainer>
              <GridItem>
                <SubTitle>{PERMIT_STATEMENTS.STANDARD_CONDITIONS}</SubTitle>
              </GridItem>
              <GridItem>
                {
                  <List dense sx={{ listStyleType: "disc", ml: "2rem" }}>
                    {permitData?.standardConditions.map((condition, index) => (
                      <ListItem
                        key={index}
                        data-testid={`permitStandardConditionList-${index}`}
                        sx={{ display: "list-item", pl: 0 }}
                      >
                        <Content1>{condition}</Content1>
                      </ListItem>
                    ))}
                  </List>
                }
              </GridItem>
            </GridContainer>
            <GridContainer>
              {permitData?.disciplineActivities.map((disciplineActivity, index) => (
                <PermitDiscipline
                  disciplineName={disciplineActivity.name}
                  disciplineAuthorisedByName={disciplineActivity.authorisedByName}
                  disciplineAuthorisedDate={disciplineActivity.authorisedDate}
                  authorisationComments={disciplineActivity.authorisationComments}
                  key={index}
                />
              ))}
            </GridContainer>
            <GridContainer>
              <GridItem>
                <Header>{PERMIT_STATEMENTS.APPENDIX_TITLE}</Header>
              </GridItem>
              <GridItem>
                <SubTitle>{PERMIT_STATEMENTS.DESCRIPTION_SUBTITLE}</SubTitle>
              </GridItem>
              <GridItem>
                <RichTextEditor
                  readOnly={true}
                  hideMenuBar={true}
                  ref={editorRef}
                  defaultValue={permitData?.description}
                  data-testid="permit-description"
                  className="permit-description"
                />
              </GridItem>
            </GridContainer>
          </GridContainer>
        </GridContainer>
      </Stack>
      <Box
        className="permit-preview-button-box"
        position={"fixed"}
        visibility={isPermitIssuedOrHasActiveVersion ? "visible" : "hidden"}
        sx={{ transform: isPrintPreview ? "translate(53vh, 0)" : "translate(83vh, 0)" }}
      >
        <Button variant="contained" startIcon={<PrintIcon />} onClick={onPrintClick} sx={{ zIndex: 5 }}>
          {isPrintPreview ? PERMIT_STATEMENTS.PRINT_STICKY_LABEL : PERMIT_STATEMENTS.PRINT_PREVIEW_STICKY_LABEL}
        </Button>
        <Button
          variant="contained"
          startIcon={<ContentCopyIcon />}
          onClick={onClickCopyLink}
          sx={{ zIndex: 5, margin: 2 }}
        >
          {PERMIT_STATEMENTS.COPY_LINK_STICKY_LABEL}
        </Button>
      </Box>
      <SnackBarComponent />
    </>
  );
}

import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { Stack, Typography } from "@mui/material";
import { colours } from "@/theme/colour";
import { Dialog } from "@/components/dialogs";
import { AppFormInput } from "@/components/fields";
import { useAuthoriseDiscipline } from "@/hooks";
import { useARContext } from "@/context";
import { useSetAtom } from "jotai";
import { snackBarAtom } from "@/stores";
import { DISCIPLINE_MESSAGE } from "@/constants";

interface OverAllocationBypassFormValues {
  overAllocationBypassReason: string;
}

interface OverAllocationBypassFormProps {
  dialogOpen: boolean;
  dismissDialog: () => void;
}

export function OverAllocationBypassForm({ dialogOpen, dismissDialog }: OverAllocationBypassFormProps) {
  const { approvalRequestId, approvalRequestDisciplineId, capturedCPSNumbers } = useARContext();

  const setSnackBar = useSetAtom(snackBarAtom);

  const { control, watch, reset, getValues } = useForm<OverAllocationBypassFormValues>({
    defaultValues: {
      overAllocationBypassReason: ""
    }
  });

  const { mutate: authoriseDiscipline } = useAuthoriseDiscipline(approvalRequestId, () => {
    setSnackBar({
      message: DISCIPLINE_MESSAGE.SAVE_SUCCESSFUL,
      open: true
    });
  });

  const [overAllocationBypassReason] = watch(["overAllocationBypassReason"]);

  const authoriseButtonDisabled = useMemo(() => {
    return !overAllocationBypassReason || overAllocationBypassReason.trim() === "";
  }, [overAllocationBypassReason]);

  const handleDialogClose = () => {
    dismissDialog();
    reset();
  };

  const handleAuthoriseClick = () => {
    const formValues = getValues();

    authoriseDiscipline({
      approvalRequestDisciplineId,
      approvalRequestId,
      overAllocationBypassReason: formValues.overAllocationBypassReason,
      overAllocatedCPSNumbers: capturedCPSNumbers
    });
    dismissDialog();
    reset();
  };

  return (
    <Dialog
      data-testid="Overallocation-bypass-dialog"
      open={dialogOpen}
      onClose={handleDialogClose}
      title={`Allocated clearing exceeds available clearing for the following instruments: ${capturedCPSNumbers}.`}
      actions={[
        {
          label: "Cancel",
          onClick: handleDialogClose
        },
        {
          label: "Authorise",
          disabled: authoriseButtonDisabled,
          onClick: handleAuthoriseClick,
          isFormSubmit: true
        }
      ]}
    >
      <Stack rowGap={2}>
        <Typography color={colours.dialog.subtextColour}>Do you wish to authorise?</Typography>
        <AppFormInput
          margin={"normal"}
          control={control}
          label={"Reason for ignoring overallocation"}
          name={"overAllocationBypassReason"}
          required={true}
          data-testid="reason-input"
          multiline={true}
          rows={4}
          maxLength={800}
        />
      </Stack>
    </Dialog>
  );
}

import { GridRowProps } from "@mui/x-data-grid";

import {
  QAAnswerItem,
  IOption,
  PaginatedList,
  ARDisciplines,
  DisciplineAuthorisationCommentValue,
  QuestionAnswer
} from "@/interfaces";
import { AUTH_COMMENT_ARRAY_REGEX, AUTH_COMMENT_CONTENT_REGEX, EMPTY_RTE_CONTENT } from "@/constants";

export const convertToIOOption = <T extends { id: string; name: string }>(items: T[]): IOption[] =>
  items.map((item) => ({
    id: item.id,
    value: item.name
  }));

export const convertToGridRows = <T>(items: T[]): GridRowProps[] => items.map((item) => item as GridRowProps);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertNullToUndefined = (object: any) => {
  for (const key in object) {
    if (typeof object[key] === "object") {
      object[key] = convertNullToUndefined(object[key]);
    }

    if (object[key] === null) {
      object[key] = undefined;
    }
  }

  return object;
};

export const isConditionalARQuestion = (answerValue: string): boolean =>
  answerValue.trim() === "" || answerValue.trim() === "no";

export const filterAndMapToQAAnswerItem = (questionAnswer: QuestionAnswer[]): QAAnswerItem[] => {
  return questionAnswer?.reduce((answers: QAAnswerItem[], question) => {
    const isConditional = isConditionalARQuestion(question.answer);
    answers.push({
      questionId: question.questionId,
      id: question.answerId,
      answer: question.answer,
      isConditional: question.isConditional,
      componentName: question.componentName
    });

    question.subQuestionAnswers.map((subAns) => {
      answers.push({
        questionId: subAns.questionId,
        id: subAns.answerId,
        answer: isConditional ? (subAns.defaultValue ?? "") : subAns.answer,
        isConditional: isConditional,
        componentName: subAns.componentName
      });
    });
    return answers;
  }, []);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getEnumValueByNumber = (enumObject: any, value: number): any | undefined => {
  const keys = Object.keys(enumObject).filter((key) => isNaN(Number(key)));
  const key = keys.find((k) => enumObject[k] === value);
  return key ? enumObject[key] : undefined;
};

/**
 * Function that takes a string value and strips it of any empty paragraph tags.
 * Additionally, also removes any whitespace only tags.
 * @param content string that contains the content to be cleaned and processed
 * @returns string that has been cleaned and processed
 */
export const cleanUpRTEContent = (content: string) => {
  let strippedContent = content;
  // Remove leading / trailing empty p tags or p tags with only whitespace in them.
  strippedContent = strippedContent.replaceAll(/^((<p>(<br>)*(\s)*<\/p>)+)|((<p>(<br>)*(\s)*<\/p>)+)$/g, "");
  // Remove extra whitespace between tags.
  strippedContent = strippedContent.replaceAll(/>\s{2,}</g, "><");

  return strippedContent;
};

/**
 * Function that takes a string and processes it to return the kebab case version of it.
 * @param text string to convert to kebab case.
 * @returns string that has been made lowercase and separated with hyphens.
 */
export const toKebabCase = (text: string) => {
  return text.toLowerCase().replaceAll(" ", "-");
};

/**
 * This function will take in paginatedList of type T and flatten along with approvalRequestStatuses
 * @param paginatedList
 * @returns object with flatten approval request discipline statuses
 */
export const flattenPaginatedARList = <PaginatedType extends PaginatedList<T>, T extends ARDisciplines>(
  paginatedList: PaginatedType
) =>
  paginatedList.items.map(({ approvalRequestDisciplineStatuses, ...paginatedList }) => ({
    ...paginatedList,
    ...approvalRequestDisciplineStatuses
  }));

export const toTitleCaseFromCamelCase = (camelText: string) => camelText.replace(/([A-Z])/g, " $1").trim();

export const getAuthCommentFieldsFromContent = (content: string) => {
  return (
    content.match(AUTH_COMMENT_CONTENT_REGEX)?.map((match) => {
      return match.replace(new RegExp("{{|}}", "g"), "");
    }) ?? []
  );
};

export const indexedDisciplineAuthCommentValues = (
  disciplineAuthCommentValues: DisciplineAuthorisationCommentValue[]
): Record<string, DisciplineAuthorisationCommentValue> => {
  return disciplineAuthCommentValues.reduce(
    (acc, authCommentValue) => {
      acc[authCommentValue.name] = authCommentValue;
      return acc;
    },
    {} as Record<string, DisciplineAuthorisationCommentValue>
  );
};

/**
 * Replaces the UUIDs in the given `disciplineAuthCommentValueName` string with "{0}".
 *
 * @param disciplineAuthCommentValueName - The string containing UUIDs.
 * @returns The modified string with UUIDs replaced by "{0}".
 */
export const getAuthCommentFormFieldName = (disciplineAuthCommentValueName: string) =>
  disciplineAuthCommentValueName.replace(/[A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12}/g, "{0}");

export const getFormLayoutFromPermitContent = (permitContent: string) => {
  const matchedPermitContent = permitContent.match(new RegExp(`(\\*{2}(.*?)\\*{2})`));
  return matchedPermitContent ? matchedPermitContent[matchedPermitContent.length - 1] : "";
};

export const getStringArrayFromString = (value: string) => {
  const isValueArray = AUTH_COMMENT_ARRAY_REGEX.test(value);
  if (!isValueArray) return value;
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

/**
 * Function that determines if an RTE value contains any content.
 * @param description optional string value to be processed
 * @returns Empty RTE value or the original value if applicable
 */
export function processRTEValue(description?: string) {
  if (description !== undefined && description.trim() === "") {
    return EMPTY_RTE_CONTENT;
  }

  return description;
}

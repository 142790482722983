import { PropertyErrorMessage, ApprovalRequestStatus } from "@/interfaces";
import { NVCP_TAG, PERMIT_TAG } from "@/constants";
import { Dispatch, SetStateAction } from "react";

export const handleNVCPUnprocessableEntityErrors = (
  propertyErrorMessages: PropertyErrorMessage[],
  setCapturedCPSNumbers: Dispatch<SetStateAction<string>>,
  setOverAllocationBypassDialogOpen: Dispatch<SetStateAction<boolean>>
) => {
  const errorMessages = propertyErrorMessages.filter((err) => err.propertyName === NVCP_TAG.OVER_ALLOCATION);
  const overAllocationErrors = propertyErrorMessages
    .map((err) =>
      err.formattedMessagePlaceholderValues ? Object.values(err.formattedMessagePlaceholderValues).join(", ") : ""
    )
    .join(", ");
  if (errorMessages.length === 0) return;
  setCapturedCPSNumbers(overAllocationErrors);
  setOverAllocationBypassDialogOpen(true);
};

export const handlePermitUnprocessableeEntityErrors = (
  approvalRequestStatus: ApprovalRequestStatus,
  propertyErrorMessages: PropertyErrorMessage[],
  setAuthorisePermitOpen: Dispatch<SetStateAction<boolean>>,
  setDisciplinePermitDateError: Dispatch<SetStateAction<PropertyErrorMessage | undefined>>
) => {
  switch (approvalRequestStatus) {
    case ApprovalRequestStatus.PermitIssued: {
      const errorMessages = propertyErrorMessages.filter((err) => err.propertyName === PERMIT_TAG.PERMIT_EXPIRY_DATE);
      if (errorMessages.length === 0) break;
      setAuthorisePermitOpen(true);
      setDisciplinePermitDateError(errorMessages[0]);
      break;
    }
  }
};

import { Grid } from "@mui/material";

interface GridItemProps {
  children: React.ReactNode;
  xs?: number;
  ml?: string;
  mt?: string;
  mr?: string;
  alignContent?: string;
  alignItems?: string;
  rowGap?: string;
}

export const GridItem = ({ children, xs, alignContent, alignItems }: GridItemProps) => {
  return (
    <Grid item xs={xs ?? 12} display="flex" alignContent={alignContent} alignItems={alignItems}>
      {children}
    </Grid>
  );
};

export const GridContainer = ({ children, xs, ml, mt, mr, alignContent, alignItems, rowGap }: GridItemProps) => {
  return (
    <Grid
      container
      xs={xs ?? 12}
      sx={{ ml, mt, mr }}
      spacing={1}
      justifyContent="space-between"
      display="flex"
      alignContent={alignContent}
      alignItems={alignItems}
      rowGap={rowGap}
    >
      {children}
    </Grid>
  );
};

import { useState, useEffect, useMemo } from "react";
import { Divider, Stack } from "@mui/material";
import { useDisciplineTabContext } from "@/context";
import { DisciplineTrackingComment } from "@/interfaces";
import { TrackingComment } from "./TrackingComment";

interface ExistingTrackingCommentsProps {
  showHistorical: boolean;
  fetchedTrackingComments: DisciplineTrackingComment[];
  hasNoTrackingCommentPermission: boolean;
}

export const ExistingTrackingComments = ({
  showHistorical,
  fetchedTrackingComments,
  hasNoTrackingCommentPermission
}: ExistingTrackingCommentsProps) => {
  const { trackingComments, updateTrackingComments } = useDisciplineTabContext();
  const [trackingCommentsById, setTrackingCommentsById] = useState<Record<string, DisciplineTrackingComment>>({});

  const fetchedTrackingCommentsIds = useMemo(
    () => fetchedTrackingComments.map(({ id }) => id),
    [fetchedTrackingComments]
  );

  const historicalTrackingComments = useMemo(
    () => fetchedTrackingComments.filter((comment) => comment.isHistorical),
    [fetchedTrackingComments]
  );

  const deleteTrackingComment = (id: string) => {
    if (fetchedTrackingCommentsIds.includes(id)) {
      const updatedTrackingComments = {
        ...trackingCommentsById,
        [id]: {
          ...trackingCommentsById[id],
          isRemoved: true
        }
      };

      setTrackingCommentsById(updatedTrackingComments);
      updateTrackingComments(Object.values(updatedTrackingComments));
      return;
    }

    const updateTCObjects = { ...trackingCommentsById };
    delete updateTCObjects[id];
    setTrackingCommentsById(updateTCObjects);
    updateTrackingComments(Object.values(updateTCObjects));
  };

  const updateTrackingComment = (updatedComment: DisciplineTrackingComment, isDirty?: boolean) => {
    const updatedTrackingCommentsById = {
      ...trackingCommentsById,
      [updatedComment.id]: updatedComment
    };

    setTrackingCommentsById(updatedTrackingCommentsById);
    updateTrackingComments(Object.values(updatedTrackingCommentsById), isDirty);
  };

  useEffect(() => {
    const indexedTrackingComments: Record<string, DisciplineTrackingComment> = {};

    trackingComments.forEach((comment) => {
      if (!comment.isHistorical) {
        indexedTrackingComments[comment.id] = comment;
      }
    });

    setTrackingCommentsById(indexedTrackingComments);
  }, [trackingComments]);

  return (
    <>
      <Stack spacing="1.5rem">
        {Object.values(trackingCommentsById).map((trackingComment, index) => {
          return !trackingComment.isRemoved ? (
            <TrackingComment
              key={`${trackingComment.id}-${index}`}
              disciplineTrackingComment={trackingComment}
              updateTrackingComment={updateTrackingComment}
              deleteTrackingComment={deleteTrackingComment}
              hasNoTrackingCommentPermission={hasNoTrackingCommentPermission}
            />
          ) : null;
        })}
        {showHistorical ? (
          <>
            {Object.values(trackingCommentsById).length > 0 ? (
              <Divider sx={{ fontSize: "0.75rem", color: "gray" }}>Historical</Divider>
            ) : null}
            {historicalTrackingComments.map((historicalTrackingComment, index) => (
              <TrackingComment
                key={`${historicalTrackingComment.id}-${index}`}
                disciplineTrackingComment={historicalTrackingComment}
                updateTrackingComment={() => {}}
                deleteTrackingComment={() => {}}
                hasNoTrackingCommentPermission={false}
              />
            ))}
          </>
        ) : null}
      </Stack>
    </>
  );
};

import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Theme,
  useTheme
} from "@mui/material";
import { useARContext } from "@/context";
import { useInternalDependencies } from "@/hooks";
import { InternalDependency } from "@/interfaces";
import { useMemo } from "react";
import { colours } from "@/theme/colour";
import { Loading } from "@/components/Loading";

interface SelectTrackingCommentDependencyProps {
  selectedDependencies: InternalDependency[];
  handleDependencyChange: (selectedDependencies: InternalDependency[]) => void;
  readOnly?: boolean;
  error?: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function getStyles(
  internalDependency: InternalDependency,
  selectedDependencies: Record<string, InternalDependency>,
  theme: Theme
) {
  if (selectedDependencies[internalDependency.internalDependencyId])
    return {
      backgroundColor: colours.selected,
      fontWeight: theme.typography.fontWeightMedium
    };

  return {
    fontWeight: theme.typography.fontWeightRegular
  };
}

export function SelectTrackingCommentDependency({
  selectedDependencies,
  handleDependencyChange,
  readOnly,
  error
}: SelectTrackingCommentDependencyProps) {
  const theme = useTheme();
  const { approvalRequestId } = useARContext();
  const { data: internalDependencies, isLoading } = useInternalDependencies(approvalRequestId);
  const selectedDependencyOptions = useMemo(
    () => Object.fromEntries(selectedDependencies.map((dependency) => [dependency.internalDependencyId, dependency])),
    [selectedDependencies]
  );
  const internalDependencyOptions = useMemo(
    () => Object.fromEntries(internalDependencies.map((dependency) => [dependency.internalDependencyId, dependency])),
    [internalDependencies]
  );

  const updateCurrentInternalDependencySelections = (
    recentlySelectedDependencies: InternalDependency[],
    currentlySelectedDependencies: Record<string, InternalDependency>
  ): InternalDependency[] => {
    recentlySelectedDependencies.forEach((updatedDependency) => {
      const { internalDependencyId } = updatedDependency;
      if (currentlySelectedDependencies[internalDependencyId]) {
        delete currentlySelectedDependencies[internalDependencyId];
      } else {
        currentlySelectedDependencies[internalDependencyId] = updatedDependency;
      }
    });

    return Object.values(currentlySelectedDependencies);
  };

  const handleChange = (event: SelectChangeEvent<InternalDependency[]>) => {
    const {
      target: { value: selectedInternalDependencyOptions }
    } = event;

    if (typeof selectedInternalDependencyOptions === "string") return;
    if (internalDependencyOptions === undefined) return;

    const recentlySelectedInternalDependencies: InternalDependency[] = selectedInternalDependencyOptions
      .filter((selectedInternalDependency) => selectedInternalDependency.internalDependencyType === undefined)
      .map((id) => internalDependencyOptions[String(id)]);

    if (recentlySelectedInternalDependencies === undefined) return;
    const updatedSelectedInternalDependencies = updateCurrentInternalDependencySelections(
      recentlySelectedInternalDependencies,
      selectedDependencyOptions
    );

    handleDependencyChange(updatedSelectedInternalDependencies);
  };

  return (
    <>
      {isLoading && <Loading />}
      <>
        {internalDependencies.length === 0 ? (
          <Stack>No dependencies found.</Stack>
        ) : (
          <FormControl sx={{ m: 1, minWidth: 300, maxWidth: 500 }} error={Boolean(error)}>
            <InputLabel
              id="internal-dependency-multiple-chip-label"
              size="small"
              date-testId="internal-dependency-multiple-chip-label"
            >
              Dependencies
            </InputLabel>
            <Select
              labelId="internal-dependency-multiple-chip-label"
              id="internal-dependency-multiple-chip"
              data-testid="tracking-comment-dependency-select-box"
              multiple
              readOnly={readOnly}
              value={selectedDependencies}
              onChange={handleChange}
              input={<OutlinedInput id="select-multiple-chip" label="Dependencies" />}
              size="small"
              renderValue={(selectedDependencies) => (
                <Box
                  sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                  data-testid="selected-internal-dependency-multiple-chip-Box"
                >
                  {selectedDependencies.map((item) => (
                    <Chip
                      size="small"
                      key={item.internalDependencyId}
                      label={internalDependencyOptions[item.internalDependencyId].displayName}
                    />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {Object.values(internalDependencyOptions).map((option) => (
                <MenuItem
                  key={option.internalDependencyId}
                  value={option.internalDependencyId}
                  style={getStyles(option, selectedDependencyOptions, theme)}
                >
                  {option.displayName}
                </MenuItem>
              ))}
            </Select>
            {<FormHelperText data-testid="tracking-comment-dependency-validation-message">{error}</FormHelperText>}
          </FormControl>
        )}
      </>
    </>
  );
}

import { Suspense, useMemo } from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/system";
import { AssignmentInd, MonitorHeart, Search, Settings } from "@mui/icons-material";
import { Stack } from "@mui/material";

import { Reporting } from "@/assets/icons";
import { PATH } from "@/constants";
import { NavigationLink } from "@/interfaces";
import { useAuthorization } from "@/context";
import { Loading } from "@/components";
import { NavigationDrawer } from "@/components/navigation";
import { useNotificationsContext } from "@/context";

const reportURL = import.meta.env.REACT_APP_REPORT_URL;

export function NavigationLayout() {
  const { isAdmin } = useAuthorization();
  const { unreadNotifications } = useNotificationsContext();

  const navigationLinks: NavigationLink[] = useMemo(
    () => [
      {
        path: PATH.SEARCH,
        label: "Search",
        icon: <Search />,
        displayOrder: 1
      },
      {
        path: PATH.MY_REQUESTS,
        label: "My Requests",
        icon: <AssignmentInd />,
        displayOrder: 2,
        badge: unreadNotifications.length > 0
      },
      {
        path: PATH.DETECTED_INTERCEPTS,
        label: "Detected Intercepts",
        icon: <MonitorHeart />,
        displayOrder: 3
      },
      {
        path: reportURL,
        label: "Reporting",
        icon: <Reporting />,
        displayOrder: 4,
        newWindow: true
      },
      {
        path: PATH.ADMIN_SETTINGS,
        label: "Settings",
        icon: <Settings />,
        displayOrder: 5,
        hidden: !isAdmin
      }
    ],
    [isAdmin, unreadNotifications.length]
  );

  return (
    <Stack direction="row" className="layout-wrapper">
      <NavigationDrawer showToggle={true} navigationLinks={navigationLinks} />
      <Box className="content-container">
        <Suspense fallback={<Loading prefix="Main Layout" />}>
          <Outlet />
        </Suspense>
      </Box>
    </Stack>
  );
}

export const PERMIT_MESSAGE = {
  LINK_COPY_SUCCESSFUL: "Link is copied"
};

export const PERMIT_TAG = {
  PERMIT_EXPIRY_DATE: "PERMIT_EXPIRY_DATE"
};

export const PERMIT_STATEMENTS = {
  TITLE: "Permit",
  SITE_PROJECT_TITLE: "Site / Project",
  CLEARING_TITLE: "Clearing (ha)",
  EXPIRY_TITLE: "Expiry",
  AUTHORISED_BY_TITLE: "Authorised By",
  COORDINATOR_TITLE: "Coordinator",
  OWNER_TITLE: "Permit Owner",
  STANDARD_CONDITIONS: "Standard Conditions",
  SME_TITLE: "SME",
  AUTHORISED_DATE_TITLE: "Authorised Date",
  AUTHORISED_ACTIVITIES: "Authorised Activities",
  APPENDIX_TITLE: "Appendix",
  DESCRIPTION_SUBTITLE: "Description",
  PRINT_STICKY_LABEL: "Print",
  PRINT_PREVIEW_STICKY_LABEL: "Print Preview",
  COPY_LINK_STICKY_LABEL: "Copy Link"
};

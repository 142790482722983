import { Stack, Typography } from "@mui/material";
import { MapOutlined } from "@mui/icons-material";
import { colours } from "@/theme/colour";

const PermitMapPlaceholder = () => {
  return (
    <Stack
      component="div"
      data-testid="permitMapPlaceholder"
      width="100%"
      height="100vh"
      bgcolor={colours.backgroundMedium}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        borderRadius: "8px",
        border: `solid 1px ${colours.backgroundLow}`
      }}
    >
      <MapOutlined
        data-testid="permitMapPlaceholderIcon"
        sx={{ fontSize: "2rem", marginBottom: "0.5rem", color: `${colours.text.bold}` }}
      />
      <Typography
        data-testid="permitMapPlaceholderTitle"
        fontSize={"1.125rem"}
        fontWeight="700"
        lineHeight={"1.75rem"}
        color={colours.text.bold}
      >
        Map is unavailable
      </Typography>
      <Typography data-testid="permitMapPlaceholderDescription" lineHeight={"1.75rem"}>
        generated on permit issue
      </Typography>
    </Stack>
  );
};

export default PermitMapPlaceholder;

import {
  APPROVAL_REQUEST_QUERY_KEY,
  AR_DISCIPLINE_AUTHORISATION_COMMENTS_QUERY_KEY,
  AR_DISCIPLINE_QUERY_KEY,
  AR_DISCIPLINE_TRACKING_COMMENTS_QUERY_KEY,
  PERMIT_QUERY_KEY
} from "@/constants";
import { APIException } from "@/interfaces";
import { AuthoriseDisciplineRequest } from "@/interfaces/api/requests/AuthoriseDiscipline";
import { DisciplineCommentsRequest } from "@/interfaces/api/requests/DisciplineComments";
import { authoriseDiscipline, updateDisciplineComments } from "@/services";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export function useUpdateDisciplineComments(
  approvalRequestId: string,
  isAuthorised: boolean,
  onSuccessCallback: () => void,
  onErrorCallback: () => void
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (object: DisciplineCommentsRequest) => {
      return updateDisciplineComments(object);
    },
    onSuccess: (_data, tabsToUpdate) => {
      if (!isAuthorised) {
        queryClient.invalidateQueries([AR_DISCIPLINE_QUERY_KEY, approvalRequestId]);
      }
      queryClient.invalidateQueries([
        AR_DISCIPLINE_AUTHORISATION_COMMENTS_QUERY_KEY,
        tabsToUpdate.approvalRequestDisciplineId
      ]);
      queryClient.invalidateQueries([
        AR_DISCIPLINE_TRACKING_COMMENTS_QUERY_KEY,
        tabsToUpdate.approvalRequestDisciplineId
      ]);
      queryClient.invalidateQueries([APPROVAL_REQUEST_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([PERMIT_QUERY_KEY, approvalRequestId]);

      onSuccessCallback();
    },
    onError: () => {
      onErrorCallback();
    }
  });
}

export function useAuthoriseDiscipline(
  approvalRequestId: string,
  onSuccessCallback?: () => void,
  onErrorCallback?: (error: APIException) => void
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (object: AuthoriseDisciplineRequest) => {
      return authoriseDiscipline(object);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([AR_DISCIPLINE_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([APPROVAL_REQUEST_QUERY_KEY, approvalRequestId]);
      onSuccessCallback?.();
    },
    onError: (error) => {
      onErrorCallback?.(error as APIException);
    }
  });
}

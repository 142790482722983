import { LoaderConfig } from "@/interfaces";

export const SAVING_REQUEST: LoaderConfig = {
  open: true,
  message: "Saving request"
};

export const AUTHORISING_DISCIPLINE: LoaderConfig = {
  open: true,
  message: "Authorising discipline"
};

import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { PropsWithChildren } from "react";

import { LAUNCHDARKLY_CLIENT_ID } from "@/constants";

const LDProvider = await asyncWithLDProvider({ clientSideID: LAUNCHDARKLY_CLIENT_ID, timeout: 5 });

export function LaunchDarklyProvider({ children }: PropsWithChildren) {
  return <LDProvider>{children}</LDProvider>;
}

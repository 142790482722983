import { Grid, Typography } from "@mui/material";

import { AR_SHOW_PEOPLE_LOCATORS } from "@/constants";

interface ARShowPeopleProps {
  requestorName: string;
  ownerName: string;
  coordinatorName: string;
  endorserName: string;
}

export function ARShowPeopleComponent({ requestorName, ownerName, coordinatorName, endorserName }: ARShowPeopleProps) {
  return (
    <>
      <Typography mb="0.75rem">People</Typography>
      <Grid container spacing={2}>
        <Grid></Grid>
        <Grid></Grid>
        <Grid item sm={12} md={3} textAlign="right">
          <Typography data-testid={AR_SHOW_PEOPLE_LOCATORS.REQUESTOR_NAME_LABEL} color="grey">
            Requestor
          </Typography>
        </Grid>
        <Grid item sm={12} md={9} data-testid={AR_SHOW_PEOPLE_LOCATORS.REQUESTOR_NAME_TEXT}>
          {requestorName}
        </Grid>
        <Grid item sm={12} md={3} textAlign="right">
          <Typography data-testid={AR_SHOW_PEOPLE_LOCATORS.OWNER_NAME_LABEL} color="grey">
            Owner
          </Typography>
        </Grid>
        <Grid item sm={12} md={9} data-testid={AR_SHOW_PEOPLE_LOCATORS.OWNER_NAME_VALUE}>
          {ownerName}
        </Grid>
        <Grid item sm={12} md={3} textAlign="right">
          <Typography data-testid={AR_SHOW_PEOPLE_LOCATORS.COORDINATOR_NAME_LABEL} color="grey">
            Coordinator
          </Typography>
        </Grid>
        <Grid item sm={12} md={9} data-testid={AR_SHOW_PEOPLE_LOCATORS.COORDINATOR_NAME_TEXT}>
          {coordinatorName}
        </Grid>
        <Grid item sm={12} md={3} textAlign="right">
          <Typography data-testid={AR_SHOW_PEOPLE_LOCATORS.ENDORSER_NAME_LABEL} color="grey">
            Endorser
          </Typography>
        </Grid>
        <Grid item sm={12} md={9} data-testid={AR_SHOW_PEOPLE_LOCATORS.ENDORSER_NAME_TEXT}>
          {endorserName}
        </Grid>
      </Grid>
    </>
  );
}

import { PropsWithChildren, useEffect } from "react";
import { useAccount, useMsal } from "@azure/msal-react";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

import { appInsightsInstance, appInsightsPlugin } from "./applicationInsights";

export function ApplicationInsightsProvider({ children }: PropsWithChildren) {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);

  useEffect(() => {
    if (appInsightsInstance && account) {
      appInsightsInstance.setAuthenticatedUserContext(account.username, account.idTokenClaims?.oid, true);
      appInsightsInstance.addTelemetryInitializer((env) => {
        env.data = {
          ...env.data,
          ["ms-user"]: account.name,
          ["ms-username"]: account.username,
          ["ms-userid"]: account.idTokenClaims?.oid
        };
      });
    }
  }, [account]);

  return (
    <AppInsightsContext.Provider value={appInsightsPlugin}>
      <>{children}</>
    </AppInsightsContext.Provider>
  );
}

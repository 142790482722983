import { Button, Menu, MenuItem, Stack, Switch, Typography } from "@mui/material";

import { EmptyTrackingComments } from "./EmptyTrackingComments";
import { useMemo, useState } from "react";
import { useARContext, useDisciplineTabContext } from "@/context";
import { ExistingTrackingComments } from "./ExistingTrackingComments";
import {
  ApprovalRequestDisciplineStatus,
  ApprovalRequestStatus,
  DisciplineTrackingComment,
  DisciplineTrackingCommentStatus,
  ReferenceDataTrackingComment
} from "@/interfaces";
import { useReferenceTrackingComment } from "@/hooks";
import { TRACKING_COMMENT } from "@/constants";

export function TrackingComments() {
  const {
    approvalRequestDisciplineId,
    trackingComments,
    currentARDiscipline,
    updateTrackingComments: setTrackingComments
  } = useDisciplineTabContext();
  const { approvalRequest, userIsSMEOfDisciplineOrAdmin } = useARContext();

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [showHistorical, setShowHistorical] = useState<boolean>(false);

  const menuIsOpen = Boolean(menuAnchor);

  const { data: referenceTrackingComments } = useReferenceTrackingComment(currentARDiscipline.disciplineId);

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const addTrackingCommentHandler = (referenceTrackingComment: ReferenceDataTrackingComment) => {
    const selectedTrackingComment: DisciplineTrackingComment = {
      id: crypto.randomUUID(),
      trackingCommentId: referenceTrackingComment.id,
      comment: referenceTrackingComment.comment,
      displayOrder: referenceTrackingComment.displayOrder,
      isComplete: false,
      isInternal: referenceTrackingComment.isInternal,
      isLegacy: referenceTrackingComment.isLegacy,
      isHistorical: false,
      isRemoved: false,
      status: DisciplineTrackingCommentStatus.InProgress,
      expectedAuthorisationDate: undefined,
      notes: [],
      approvalRequestDisciplineId: approvalRequestDisciplineId,
      disciplineTrackingCommentDependencies: []
    };
    setTrackingComments([selectedTrackingComment, ...trackingComments], true);
  };

  const currentTCExists = useMemo(
    () => trackingComments.filter((tc) => !tc.isHistorical && !tc.isRemoved).length > 0,
    [trackingComments]
  );

  const historicalTCExists = useMemo(
    () => trackingComments.filter((tc) => tc.isHistorical).length > 0,
    [trackingComments]
  );

  const hasNoTrackingCommentPermission = useMemo(
    () =>
      currentARDiscipline.status === ApprovalRequestDisciplineStatus.Authorised ||
      approvalRequest.approvalRequestStatus !== ApprovalRequestStatus.Distributed ||
      !userIsSMEOfDisciplineOrAdmin,
    [approvalRequest.approvalRequestStatus, currentARDiscipline.status, userIsSMEOfDisciplineOrAdmin]
  );
  return (
    <Stack>
      <Stack direction="row" height="3rem" alignItems="center" columnGap={1}>
        <Typography sx={{ fontSize: "1.25rem" }}>{TRACKING_COMMENT.TITLE}</Typography>
        <Button
          onClick={(event) => setMenuAnchor(event.currentTarget)}
          disabled={hasNoTrackingCommentPermission}
          data-testid="button-add-tracking-comment"
        >
          Add
        </Button>
        <Menu
          id="tracking-comment-menu"
          anchorEl={menuAnchor}
          open={menuIsOpen}
          onClose={handleCloseMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button"
          }}
          aria-disabled={!userIsSMEOfDisciplineOrAdmin}
        >
          {referenceTrackingComments.map((comment) => (
            <MenuItem
              value={comment.id}
              onClick={() => {
                addTrackingCommentHandler(comment);
                handleCloseMenu();
              }}
            >
              {comment.comment}
            </MenuItem>
          ))}
        </Menu>
        {approvalRequest.cycle !== undefined ? (
          <>
            <Switch
              checked={showHistorical}
              onChange={(_, checked) => setShowHistorical(checked)}
              inputProps={{ "aria-label": "controlled" }}
              sx={{ marginLeft: "auto" }}
              data-testid="show-historical-tracking-switch"
              disabled={!historicalTCExists}
            />
            <Typography>{TRACKING_COMMENT.LABEL_SHOW_HISTORICAL}</Typography>
          </>
        ) : null}
      </Stack>
      {currentTCExists || (showHistorical && historicalTCExists) ? (
        <ExistingTrackingComments
          showHistorical={showHistorical}
          fetchedTrackingComments={trackingComments}
          hasNoTrackingCommentPermission={hasNoTrackingCommentPermission}
        />
      ) : (
        <EmptyTrackingComments />
      )}
    </Stack>
  );
}

import { styled, Typography } from "@mui/material";

export const Header = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: "2rem",
  lineHeight: "2.7rem",
  letterSpacing: "0"
}));

export const Title1 = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: "1.375rem",
  lineHeight: "1.86rem",
  letterSpacing: "0"
}));

export const Title2 = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: "1.125rem",
  lineHeight: "1.518",
  letterSpacing: "0"
}));

export const SubTitle = styled(Typography)(() => ({
  fontWeight: 300,
  fontSize: "1rem",
  lineHeight: "1.35rem",
  letterSpacing: "0"
}));

export const Content1 = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: "1rem",
  lineHeight: "1.35rem",
  letterSpacing: "0",
  display: "inline",
  justifyContent: "space-between"
}));

export const Content2 = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: "1rem",
  lineHeight: "1.172rem",
  letterSpacing: "0",
  display: "inline",
  justifyContent: "space-between"
}));

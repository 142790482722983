import {
  AuthenticationResult,
  AuthError,
  BrowserCacheLocation,
  createStandardPublicClientApplication,
  EventMessage,
  EventType
} from "@azure/msal-browser";

export const msalInstance = await createStandardPublicClientApplication({
  auth: {
    clientId: import.meta.env.REACT_APP_AUTH_CLIENTID || "",
    authority: import.meta.env.REACT_APP_AUTH_AUTHORITY,
    redirectUri: import.meta.env.REACT_APP_AUTH_REDIRECT_URI
  },
  cache: {
    // LocalStorage allows auth token to be shared across tabs
    // Auth artifacts will be encrypted with AES-GCM using HKDF to derive the key.
    // The base key is stored in a session cookie
    cacheLocation: BrowserCacheLocation.LocalStorage
  }
});

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

msalInstance.addEventCallback((message: EventMessage) => {
  if (message.eventType === EventType.LOGIN_FAILURE) {
    if (message.error instanceof AuthError) {
      //TODO add implementation when the error handling is done
      console.error(message);
    }
  }
});

export const msalScopes = import.meta.env.REACT_APP_AUTH_APISCOPES?.split(",") || [];

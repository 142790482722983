import {
  DataGridPro,
  GridColDef,
  GridPinnedRowsProp,
  GridRenderCellParams,
  GridRowParams,
  GridRowSelectionModel
} from "@mui/x-data-grid-pro";
import { ClearingAllocation, DisciplineAuthorisationCommentValue } from "@/interfaces";
import { useEffect, useState } from "react";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { EPBCClearingAllocationTableToolbar } from "./EPBCClearingAllocationTableToolbar";

interface EPBCClearingAllocationTableProps {
  disciplineAuthCommentValue: DisciplineAuthorisationCommentValue;
  disabled: boolean;
  onUpdate: (updatedValue: DisciplineAuthorisationCommentValue) => void;
  isEditable: boolean;
  onActionHandler: (isEdit: boolean) => void;
  isViewMode?: boolean;
}

export function EPBCClearingAllocationTable({
  disabled,
  onActionHandler,
  disciplineAuthCommentValue,
  onUpdate,
  isViewMode
}: EPBCClearingAllocationTableProps) {
  const [selectedRow, setSelectedRow] = useState<ClearingAllocation>();
  const [rowIsSelected, setRowIsSelected] = useState<boolean>(false);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [clearingAllocations, setClearingAllocations] = useState<ClearingAllocation[]>([]);
  const [modalDialogAction, setModalDialogAction] = useState<"Add" | "Edit">();
  const modalDialogIsOpen = modalDialogAction !== undefined;

  const dataColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id"
    },
    {
      field: "referenceNo",
      headerName: "EPBC Reference",
      flex: 0.15,
      sortable: false,
      renderCell: (params: GridRenderCellParams<ClearingAllocation, string>) =>
        params.row.id === "Total" ? <span style={{ fontWeight: "bold" }}>Total</span> : <span>{params.value}</span>
    },
    {
      field: "clearingAllocation",
      headerName: "Clearing Allocation",
      flex: 0.15,
      renderCell: (params: GridRenderCellParams<ClearingAllocation, number>) =>
        renderDisplayValueWithUnit(params.value),
      type: "number",
      headerAlign: "right",
      align: "right",
      sortable: false,
      headerClassName: "clearingAllocationTableHeader"
    },
    {
      field: "restrictedClearingId",
      headerName: "Restricted Clearing",
      flex: 0.46,
      sortable: false,
      renderCell: (params: GridRenderCellParams<ClearingAllocation, string>) => <span>{params.value}</span>
    },
    {
      field: "restrictedClearingAllocation",
      headerName: "Restricted Clearing Allocation",
      flex: 0.24,
      renderCell: (params: GridRenderCellParams<ClearingAllocation, number>) =>
        renderDisplayValueWithUnit(params.value),
      type: "number",
      headerAlign: "right",
      align: "right",
      sortable: false,
      headerClassName: "clearingAllocationTableHeader"
    }
  ];

  useEffect(() => {
    if (disciplineAuthCommentValue.value === "") return;
    const parsedValue = JSON.parse(disciplineAuthCommentValue.value) as ClearingAllocation[];
    setClearingAllocations(parsedValue);
  }, [disciplineAuthCommentValue.value, isViewMode]);

  const renderDisplayValueWithUnit = (value?: number | null) => {
    return !value ? null : (
      <span>
        <span>{value?.toFixed(2)}</span>
        <span style={{ color: "GrayText" }}> ha</span>
      </span>
    );
  };

  const pinnedRows: GridPinnedRowsProp<ClearingAllocation> = {
    bottom: [
      {
        id: "Total",
        clearingAllocation: clearingAllocations.reduce((sum, row) => sum + (row.clearingAllocation ?? 0), 0),
        restrictedClearingAllocation: clearingAllocations.reduce(
          (sum, row) => sum + (row.restrictedClearingAllocation ?? 0),
          0
        )
      }
    ]
  };

  const handleClickAway = () => {
    if (!rowIsSelected || modalDialogIsOpen) return;
    setRowSelectionModel([]);
    resetRowToUnselectedState();
  };

  const resetRowToUnselectedState = () => {
    setSelectedRow(undefined);
    setRowIsSelected(false);
    onActionHandler(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseDown" touchEvent="onTouchStart">
      <DataGridPro
        autoHeight={true}
        columns={dataColumns}
        data-testid="epbc-clearing-allocation-table"
        density="compact"
        disableColumnMenu={true}
        pagination={false}
        getRowId={(row: ClearingAllocation) => row.id}
        rowCount={disciplineAuthCommentValue.value.length}
        rows={clearingAllocations}
        pinnedRows={clearingAllocations.length !== 0 ? pinnedRows : undefined}
        slots={{ toolbar: EPBCClearingAllocationTableToolbar }}
        slotProps={{
          toolbar: {
            setModalDialogAction,
            modalDialogAction,
            disabled,
            rowIsSelected,
            selectedRow,
            setSelectedRow,
            clearingAllocations,
            disciplineAuthCommentValue,
            onUpdate,
            isViewMode
          }
        }}
        onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel) => {
          // If whole auth comment is read only, this will prevent user to select a row.

          if (!isViewMode && disabled) {
            return;
          } else {
            // Shim: disableMultipleRowSelection and checkboxSelection doesn't work together at DataGrid v6
            // See: https://github.com/mui/mui-x/issues/5286
            setRowSelectionModel((prevModel) => {
              const newRowSelectionModel = rowSelectionModel.filter((newId) => !prevModel.includes(newId));

              // Edit and Delete button state control
              if (newRowSelectionModel.length > 0) {
                onActionHandler(true);
                setSelectedRow(clearingAllocations.filter((row) => row.id === newRowSelectionModel[0].toString())[0]);
                setRowIsSelected(true);
              } else {
                setTimeout(() => {
                  resetRowToUnselectedState();
                });
              }

              return newRowSelectionModel;
            });
          }
        }}
        checkboxSelection={true}
        disableRowSelectionOnClick={rowSelectionModel.length === 0 && disabled && !isViewMode}
        disableMultipleRowSelection={true}
        columnVisibilityModel={{
          id: false,
          __check__: false
        }}
        rowSelectionModel={rowSelectionModel}
        isRowSelectable={(params: GridRowParams<ClearingAllocation>) => params.row.id !== "Total"}
        hideFooter={true}
        sx={{
          border: 0,
          cursor: "pointer",
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal"
          },
          "& .MuiDataGrid-columnHeader": {
            // Forced to use important since overriding inline styles
            height: "unset !important"
          },
          "& .MuiDataGrid-columnHeaders": {
            // Forced to use important since overriding inline styles
            maxHeight: "168px !important"
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer": {
            pl: 1
          },
          "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
            outline: "none"
          }
        }}
      />
    </ClickAwayListener>
  );
}

import { createRef, useCallback, useEffect, useMemo } from "react";
import { RichTextEditor, RichTextEditorRef } from "@/components/rich-text-editor";
import { AUTH_COMMENT_CONTENT_REGEX, PERMIT_STATEMENTS } from "@/constants";
import { ClearingAllocation, CommentValue, IOption, PermitAuthorisationComment } from "@/interfaces";
import { formatDate, getAuthCommentFormFieldName, getFormLayoutFromPermitContent } from "@/utils";
import { Stack } from "@mui/material";
import { GridItem } from "./PermitGrid";
import { Header, SubTitle, Title1 } from "./PermitTypography";

interface PermitDisciplineProps {
  disciplineName: string;
  disciplineAuthorisedByName: string;
  disciplineAuthorisedDate: Date;
  authorisationComments: PermitAuthorisationComment[];
}

const clearingTableTitleTypeMap = {
  "epbc-table": "EPBC Reference",
  "nvcp-table": "NVCP",
  "msi-table": "MS"
};

type ClearingTableTypeKeys = keyof typeof clearingTableTitleTypeMap;

export function PermitDiscipline({
  disciplineName,
  disciplineAuthorisedByName,
  disciplineAuthorisedDate,
  authorisationComments
}: PermitDisciplineProps) {
  const editorRef = createRef<RichTextEditorRef>();

  const getChipArrayValue = useCallback((commentValue: string): string => {
    const valueInJson = commentValue ? (JSON.parse(commentValue) as IOption[]) : [];
    return valueInJson.map((a) => a.value).join(", ");
  }, []);

  const getDateValue = useCallback((commentValue: string): string => {
    return formatDate(commentValue) ?? "";
  }, []);

  const getClearingTableValue = useCallback((commentValue: CommentValue): string => {
    const valuesInJson = commentValue ? JSON.parse(commentValue.value.toString()) : [];
    const totalNumberOfValuesFromZeroIndex = valuesInJson.length - 1;
    const titleKey: ClearingTableTypeKeys = commentValue.componentType as ClearingTableTypeKeys;
    const titleValue = clearingTableTitleTypeMap[titleKey];

    const values: string[] = [];

    valuesInJson.map((value: ClearingAllocation, index: number) => {
      values.push(`
        <ul>
          <li>
            <p>${titleValue}: ${value.referenceNo}</p>
            <p></p>
            <p>Clearing Allocation: ${value.clearingAllocation} ha</p>
            ${titleKey === "nvcp-table" && value.noClearingAfterDate ? `<p>No clearing after: ${formatDate(value.noClearingAfterDate)}</p>` : ""}
            ${value.restrictedClearingId ? `<p>${titleKey === "nvcp-table" ? value.restrictedClearing : value.restrictedClearingId}: ${value.restrictedClearingAllocation} ha</p>` : ""}
            ${value.termsAndConditions ? `<p>${value.termsAndConditions}</p>` : ""} 
            ${index !== totalNumberOfValuesFromZeroIndex ? "<p></p>" : ""}
          </li>
        </ul>
      `);
    });
    return values.join("");
  }, []);

  const getPermitFormatValueByComponentType = useCallback(
    (commentValue: CommentValue) => {
      let formattedAuthCommentValue = "";
      switch (commentValue.componentType) {
        case "chipArray":
          formattedAuthCommentValue = getChipArrayValue(commentValue.value.toString());
          break;
        case "date":
          formattedAuthCommentValue = getDateValue(commentValue.value.toString());
          break;
        case "epbc-table":
          formattedAuthCommentValue = getClearingTableValue(commentValue);
          break;
        case "nvcp-table":
          formattedAuthCommentValue = getClearingTableValue(commentValue);
          break;
        case "msi-table":
          formattedAuthCommentValue = getClearingTableValue(commentValue);
          break;
        default:
          formattedAuthCommentValue = commentValue.value.toString();
      }
      return formattedAuthCommentValue;
    },
    [getChipArrayValue, getDateValue, getClearingTableValue]
  );

  const getChildContentValues = useCallback(
    (authCommentFormValues: object, permitContent: string): string => {
      let childContentValues = getFormLayoutFromPermitContent(permitContent);
      if (JSON.stringify(authCommentFormValues) === "{}") return "";
      Object.entries(authCommentFormValues).forEach(([disciplineAuthCommentValueName, authCommentFormValue]) => {
        childContentValues = childContentValues.replace(
          getAuthCommentFormFieldName(disciplineAuthCommentValueName),
          getPermitFormatValueByComponentType(authCommentFormValue)
        );
      });
      return childContentValues;
    },
    [getPermitFormatValueByComponentType]
  );

  const disciplinePermitComments = useMemo(() => {
    const comments: string[] = [];
    authorisationComments.forEach((authComment) => {
      let permitContent = authComment.permitContent;
      let formCommentValue: string = "";
      Object.entries(authComment.commentValues).forEach(([key, commentValue]) => {
        if (commentValue.componentType === "form") {
          formCommentValue +=
            typeof commentValue.value === "object"
              ? getChildContentValues(commentValue.value, permitContent)
              : commentValue.value;
        }
        permitContent = permitContent.replaceAll(key, getPermitFormatValueByComponentType(commentValue));
      });

      const formLayout = getFormLayoutFromPermitContent(permitContent);
      if (formCommentValue === "") {
        formCommentValue = formLayout;
        formCommentValue.match(AUTH_COMMENT_CONTENT_REGEX)?.map((match) => {
          formCommentValue = formCommentValue.replaceAll(match, "");
        });
      }
      permitContent = permitContent.replaceAll(`**${formLayout}**`, formCommentValue);

      Object.entries(authComment.defaultValues).forEach(([key, defaultValue]) => {
        permitContent = permitContent.replaceAll(key, defaultValue);
      });

      comments.push(`<li>${permitContent}</li>`);
    });

    return `<ol>${comments.join("")}</ol>`;
  }, [authorisationComments, getChildContentValues, getPermitFormatValueByComponentType]);

  useEffect(() => {
    editorRef.current?.reset(disciplinePermitComments);
  }, [disciplinePermitComments, editorRef]);

  return (
    <>
      <GridItem>
        <GridItem xs={4.5}>
          <Header>{disciplineName}</Header>
        </GridItem>
        <GridItem xs={4.5}>
          <Stack direction="row" spacing={2} alignItems={"baseline"}>
            <SubTitle>{PERMIT_STATEMENTS.SME_TITLE}</SubTitle>
            <Title1>{disciplineAuthorisedByName}</Title1>
          </Stack>
        </GridItem>
        <GridItem xs={3}>
          <Stack direction="row" spacing={2} alignItems={"baseline"}>
            <SubTitle>{PERMIT_STATEMENTS.AUTHORISED_DATE_TITLE}</SubTitle>
            <Title1>{formatDate(disciplineAuthorisedDate)}</Title1>
          </Stack>
        </GridItem>
      </GridItem>
      <GridItem>
        <SubTitle>{PERMIT_STATEMENTS.AUTHORISED_ACTIVITIES}</SubTitle>
      </GridItem>
      <GridItem>
        <RichTextEditor
          readOnly={true}
          hideMenuBar={true}
          ref={editorRef}
          defaultValue={disciplinePermitComments}
          data-testid="auth-comments-editor"
          className="permit-auth-comment"
        />
      </GridItem>
    </>
  );
}
